import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from 'react-icons/fi'
import { MdRoofing } from 'react-icons/md'
import { FaHandshake } from 'react-icons/fa'


function Values() {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: <FiTarget fontSize={90} className='bg-2 px-4 rounded-t-full text-white' />,
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: <FaHandshake fontSize={90} className='bg-2 px-4 rounded-t-full text-white'/>,
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: <MdRoofing fontSize={90} className='bg-2 px-4 rounded-t-full text-white'/>,
    },

  ]


  return (
    <>
      <div className='h-auto w-full flex justify-center flex-wrap my-5 py-10'>
        {
          valuesData.map((items, index) => {

            return (
              <div key={index} className='w-[320px] lg:w-1/4 h-auto '>
                <div className='w-full flex justify-center'>
                  <div className='pb-4'>
                    {items.icon}
                  </div>
                </div>
                <div className='w-full h-[380px] p-3 border-x-[1px]'>
                  <h2 className="text-2xl text-center uppercase pb-4">{items.title}</h2>
                  <p className='text-center pb-3 px-5'>
                    {items.description}
                  </p>
                </div>
              </div>
            )

          })
        }

      </div>
    </>
  );
}

export default Values;
