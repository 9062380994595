import React, { useContext } from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';
import imgDefault from '../../assets/image/placeholder.png'


const Block_9 = ({ title, text, listsAbout, listsServices, image1, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#efefef] py-[150px] flex flex-col lg:flex-row'>
            <div className='w-full lg:w-1/2 h-auto flex flex-col items-end'>
                <div className='w-full lg:w-4/5 h-auto p-5'>
                    {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h3 className='pb-10 capitalize'>
                                    {
                                        rpdata?.dbPrincipal?.exprYears ?
                                            `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                            : 'we have many years of experience'
                                    }
                                </h3>
                            :
                            <h2 className='pb-3 capitalize'>
                                {
                                    title ? title :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h2>
                    }
                    <p className='pb-3'>
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>
                    {
                        listsAbout ?
                            <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                {
                                    rpdata?.dbAbout?.[0].list.length > 1 ?
                                        rpdata?.dbAbout?.[0].list.map((item, index) => {
                                            return (

                                                <li key={index} className="py-2 flex items-center">
                                                    <BsArrowReturnRight />
                                                    <span className="pl-2">{item}</span>
                                                </li>
                                            )
                                        })
                                        : null
                                }
                            </ul>
                            : null
                    }
                    {
                        listsServices ?
                            <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                    return (
                                        <li key={index} className="py-1 flex items-center">
                                            <BsArrowReturnRight />
                                            <span>{item.name}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                            : null
                    }
                    <div>
                        <ButtonContent />
                    </div>
                </div>
            </div>
            <div className='relative w-full lg:w-1/2 h-auto rounded-l-3xl roun bg-cover bg-center' style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}>
                <div className='hidden md:flex w-[300px] h-[160px] bg-2 rounded-br-3xl rounded-tl-xl items-center justify-center'>
                    <h3 className='text-[22px] text-white'>Cost-Effectiveness <br /> & High-Quality</h3>
                </div>
                <div className='absolute hidden md:flex items-center justify-center w-[350px] h-[160px] bg-slate-100 rounded-2xl left-[30%] -bottom-20 shadow-2xl'>
                    <h3 className='text-[36px] font-extrabold text-[#080572] capitalize text-center p-2'>
                        {
                            rpdata?.dbPrincipal?.exprYears ?
                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                : 'we have many years of experience'
                        }
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default Block_9